(function () {
  "use strict";

  const envName = "development";
  let env = {};
  switch (envName) {
    case "local-jav":
      env = {
        api: "http://localhost:5000",
        wss: "ws://localhost:5000/channel",
      };
      break;

    case "local":
      env = {
        api: "http://api.bioscann.dev:2000/api",
        wss: "ws://api.bioscann.dev:2000/channel",
      };
      break;

    case "development":
      env = {
        api: "https://v2-dev.api.bioscann.org",
        wss: "wss://v2-dev.api.bioscann.org/channel",
      };
      break;
    case "staging":
      env = {
        api: "http://localhost:3000",
        wss: "ws://localhost:3000/channel",
      };
      break;
    case "v2prod":
      env = {
        api: "https://v2.api.bioscann.org",
        wss: "ws://api-stage.bioscann.org/channel",
      };
      break;
    case "prod":
      env = {
        api: "https://api.bioscann.org/api",
        wss: "wss://api.bioscann.org/channel",
      };
      break;
    default:
      env = {
        api: "https://v2-dev.api.bioscann.org",
        wss: "wss://v2-dev.api.bioscann.org/channel",
      };
  }
  env.userRoles = ["Admin", "Researcher", "Author", "Teacher", "Learner"];

  /**
   * @ngdoc service
   * @name bioscann.constant:env
   *
   * @description
   *
   */
  angular.module("bioscann").constant("env", env);
})();
